import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Summary from "./components/Summary";
import Experience from "./components/Experience";
import ReactGA from 'react-ga4';

import Portfolio from "./components/Portfolio";
import { Testimonials } from "./components/Testimonials";
import { Contacts } from "./components/Contacts";


function App() {

  ReactGA.initialize('G-3ER1F23ZP6');

  return (
    <>
    <Navbar/>
    <Header/>
    <AboutMe/>
    <Summary/>
     <Experience/>
     {/* <Portfolio/> */}
    {/* <Testimonials/> */}
    {/* <Contacts/> */}
    </>
  );
}

export default App;
